import React from "react"
import {isChrome,isFirefox,isIeEdge} from "@tightrope/lpscripts/browserdetect"
import Styles from "./css/firstrun.module.scss"
import Footerlinkssingle from "@tightrope/footerlinks/footerlinks_single"

class FirstRun extends React.Component {
  constructor(props){
    super(props);
    this.state = ({data: {}});
  }
  componentDidMount(){
    if(this.props.data){
      this.state = {
        data: this.props.data
      }
    }
  }
  render() {
    return (
        <div id = {Styles.firstrun}>
          <body>
            <div class={Styles.pageContainer}>
              <div class={Styles.header}>
                <img src="/img/fabtab-logo.png" />
              </div>

              <div class={Styles.centerWrap}>
                <h1>Watch video for Step-by-Step Tutorial</h1>
                 <h2>Print web pages in an easy to read format, remove ads and images to save ink, and highlight important information in 3 easy steps</h2>
                <div class={Styles.center}>
                  <div class={Styles.stepsCol}>
                    <div class={Styles.steps}>
                      <h4>Select</h4>
                      <p>

                        Browse the web to find a page to print and click the Fab Tab icon </p>
                    </div>
                    <div class={Styles.steps}>
                      <h4>Edit</h4>
                      <p>Preview in Clean View to edit font size, keep or remove images, and highlight important text</p>
                    </div>
                    <div class={Styles.steps}>
                      <h4>Print</h4>
                      <p>
                        Simply print your page or select another page to edit and print </p>
                    </div>
                  </div>

                  <div class={Styles.videoContainer}>
                    <video id={Styles.tut} src="/img/ep-clear-tut.mp4" controls autoPlay loop muted></video>
                  </div>
                </div>
              </div>

              <div class={Styles.footerLinks}>
     <ul>
       <li>Tightrope Interactive </li>
       <li><a href="/terms-of-service/" target="_blank">Terms</a></li>
       <li><a href="/privacy-policy/" target="_blank">Privacy</a></li>
       <li><a href="/contact-us/" target="_blank">Contact</a></li>
       <li><a href="/uninstall/" target="_blank">Uninstall</a></li>
       <li>All trademarks are property of their respective owners</li>
       <li><a href="/privacy-policy/#cali-consumer/">Do Not Sell My Personal Information</a></li>
     </ul>
   </div>

            </div>
          </body>


        </div>
    )
  }
}
export default FirstRun
